import React from 'react';
import { BigCard } from '../hooks/useBigCards';
import { SmallCard } from '../hooks/useSmallCards';
import { GatsbySeo } from 'gatsby-plugin-next-seo';


const Dashboard: React.FuncionComponent = () => {
  const { products } = BigCard();
  const { smallers } = SmallCard();

  return (
    <>
   
      <GatsbySeo
        title="Casa Fraine | Dashboard"
        description={`Le mie attivita' di sussistenza visualiizate in questa pagina`}
        canonical="https://www.casafraine.com/dashboard"
        openGraph={{
          url: 'https://www.casafraine.com/dashboard',
          title: 'Dashboard',
          description: `Le mie attivita' di sussistenza visualiizate in questa pagina`,
        
          images: [
            {
              url: 'https://www.example.ie/og-image-01.jpg',
              width: 800,
              height: 600,
              alt: 'Botanic Api',
            },
            {
              url: 'https://www.example.ie/og-image-02.jpg',
              width: 900,
              height: 800,
              alt: 'Botanic Api',
            },
            { url: 'https://www.example.ie/og-image-03.jpg' },
            { url: 'https://www.example.ie/og-image-04.jpg' },
          ],
          site_name: 'Casa Fraine',
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
      <section className="dashboard">
        <div className="header">
          <div className="header-left">
            <h1 className="title">Dispendio Fraine</h1>
            <span>from 2020 on</span>
          </div>
        </div>
        <div id="upper-container" className="upper-container">
          {products.map((card, index) => {
            return (
              <div key={index} className="flip-card">
                <div className="flip-card-inner">
                  <div
                    className={`card-big  flip-card-front  ${
                      card.tipoDiQuantita === 'metri cubi'
                        ? 'border-top-facebook'
                        : card.tipoDiQuantita === 'Kg'
                        ? 'border-top-youtube'
                        : card.tipoDiQuantita === 'kwh'
                        ? 'border-top-elect'
                        : 'border-top-law'
                    }`}
                  >
                    <div className="card-head">
                      <img
                        style={{
                          maxWidth: '100%',
                          maxHeight: '3rem',
                          margin: 'auto',
                        }}
                        src={card.image.url}
                        alt={card.description}
                      />
                    </div>
                    <div className="card-body">
                      <strong>{card.quantita}</strong>
                      <span>{card.tipoDiQuantita}</span>
                    </div>
                    <div
                      className={
                        card.quantitaOdierna >= 0
                          ? 'decrement bottom'
                          : ' increment bottom'
                      }
                    >
                      <svg
                        className={card.quantitaOdierna >= 0 ? 'red' : 'green'}
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="4"
                      >
                        <path fill="" fillRule="evenodd" d="M0 4l4-4 4 4z" />
                      </svg>
                      {card.quantitaOdierna} Oggi
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<ul>${card.description}</ul>`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* lower section */}

        <h3 className="title overview">Altri piccoli dispendi - ad oggi</h3>
        <div className="lower-container">
          {smallers.map((card, index) => {
            return (
              <div key={index} className="flip-card small">
                <div className="flip-card-inner small">
                  <div className="card-small  flip-card-front">
                    <div className="first-row">
                      <span> {card.tipodiprodotto}</span>
                      <img
                        src={card.image.url}
                        alt={card.tipodiprodotto}
                        width="40"
                        height="40"
                        style={{ objectFit: 'cover' }}
                      />
                    </div>
                    <div className="second-row">
                      <small>
                        <strong>{card.quantitadiprodotto}&nbsp;</strong>
                      </small>
                      <div
                        className={
                          card.movimentiGiornalieri >= 0
                            ? 'increment'
                            : 'decrement'
                        }
                      >
                        <svg
                          className={
                            card.movimentiGiornalieri >= 0 ? 'green' : 'red'
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="4"
                        >
                          <path fill="" fillRule="evenodd" d="M0 4l4-4 4 4z" />
                        </svg>
                        {card.movimentiGiornalieri}%
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<ul>${card.descrizione}</ul>`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Dashboard;
