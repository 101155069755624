import { graphql, useStaticQuery } from 'gatsby';

export const BigCard = () => {
  const { gcms = {} } = useStaticQuery(graphql`
    {
      gcms {
        products {
          nomeDispendio
          quantita
          tipoDiQuantita
          quantitaOdierna
          description
          image {
            url
          }
        }
      }
    }
  `);

  let { products } = gcms;

  return {
    products,
  };
};
