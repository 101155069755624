import { graphql, useStaticQuery } from 'gatsby';

export const SmallCard = () => {
  const { gcms = {} } = useStaticQuery(graphql`
    {
      gcms {
        smallers {
          tipodiprodotto
          image {
            url
          }
          quantitadiprodotto
          movimentiGiornalieri
          descrizione
        }
      }
    }
  `);

  let { smallers } = gcms;

  return {
    smallers,
  };
};
